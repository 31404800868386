import { Component, OnInit } from "@angular/core";
import { ThemeOptions } from "src/app/modules/main/ThemeOptions/store/theme-options";
import { Router } from "@angular/router";
import { PARENT_PATH } from "src/app/common/constant";

@Component({
  selector: "app-user-box",
  templateUrl: "./user-box.component.html",
})
export class UserBoxComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    this.router.navigateByUrl(`${PARENT_PATH.AUTH}`);
  }
}
