import { Component } from "@angular/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  title =
    "ArchitectUI - Angular 7 Bootstrap 5 & Material Design Admin Dashboard Template";
}
