import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
@Injectable({
  providedIn: "root",
})
export class ToasterService {
  constructor(private toastr: ToastrService) {}

  successToastr(message?: string): void {
    this.toastr.success(message);
  }

  errToastr(message?: string) {
    this.toastr.error(message);
  }

  infoToastr(message?: string) {
    this.toastr.info(message);
  }
}
