<div [class]="'app-header header-shadow '" style="background: #02305c;">
    <!-- 'app-header header-shadow ' + (config$ | async).headerTheme -->
    <div class="logo-src"></div>
    <div class="app-header__content" style="background: #02305c;">
        <div class="app-header-left">
            <!-- <app-search-box></app-search-box> -->
        </div>
        <div class="app-header-right">
            <app-user-box></app-user-box>
        </div>
    </div>
    <div class="app-header__mobile-menu">
        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" [ngClass]="{'is-active' : globals.toggleSidebarMobile}" (click)="toggleSidebarMobile()">
        <span class="hamburger-box" style="margin-top: 7px;">
            <span class="hamburger-inner"></span>
        </span>
    </button>
    </div>
    <div class="app-header__menu">
        <span style="margin-top: 7px;">
            <button type="button" class="mb-2 me-2 btn" style="color: #02305c;background:white;" (click)="logout()">Logout</button>
        <!-- <button class="btn-icon btn-icon-only btn btn-primary" [ngClass]="{'is-active' : globals.toggleHeaderMobile}"
                (click)="toggleHeaderMobile()">
            <div class="btn-icon-wrapper">
                <fa-icon [icon]="faEllipsisV"></fa-icon>
            </div>
        </button> -->
    </span>
    </div>
</div>