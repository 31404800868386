import { Component, OnInit } from "@angular/core";
import { ModalService } from "src/app/core/service/modal.service";

@Component({
  selector: "app-delete-tuitor",
  templateUrl: "./delete-tuitor.component.html",
  styleUrls: ["./delete-tuitor.component.css"],
})
export class DeleteTuitorComponent implements OnInit {
  constructor(private modal: ModalService) {}

  ngOnInit(): void {}

  closeModal() {
    this.modal.closeModal();
  }
}
