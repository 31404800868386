import { Injectable } from "@angular/core";
import { Authenticate } from "src/app/graphql/service";
import { loginModel } from "src/app/core/modals/modal";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private service: Authenticate) {}

  signIn(data: loginModel) {
    return this.service.signIn(data);
  }

  public isLoggedIn() {
    return localStorage.getItem("access_token") !== null;
  }

  public refreshToken() {
    return this.service.refreshToken();
  }
}
