import { DocumentNode } from "graphql";
import gql from "graphql-tag";

/**
 * model for login api
 */
export const LOGIN: DocumentNode = gql`
  mutation login($loginInput: LoginInput!) {
    login(loginInput: $loginInput) {
      name
      email
      mobile
      access_token
      refresh_token
    }
  }
`;

/**
 * model to get list of users (tuitor/students)
 */
export const ADMIN_LIST_USER: DocumentNode = gql`
  query ($listUserInput: ListUserInput!) {
    adminListUser(listUserInput: $listUserInput) {
      adminListUser {
        id
        name
        email
        mobile
        country_code
        dob
        email_verified
        is_admin_verified
        profile_picture
        created_at
        role {
          id
          name
        }
        status {
          id
          name
        }
        userAdditionalInformation {
          id
          total_credits
          overall_rating
        }
        subscriptions {
          id
          subscr_id
          price
          gateway
          period
          period_type
          status
          inApp_trans_num
          created_at
          transactions {
            id
            amount
            trans_num
            status
            gateway
            created_at
            expires_at
          }
        }
      }
      refetch
      count
    }
  }
`;

/**
 * model to get user profile who logged in currently
 */
export const GET_ADMIN_USER_PROFILE: DocumentNode = gql`
  query {
    adminGetUser {
      id
      name
      email
      mobile
      country_code
      dob
      email_verified
      is_admin_verified
      profile_picture
      role {
        id
        name
      }
      status {
        id
        name
      }
      userAdditionalInformation {
        id
        total_credits
        overall_rating
      }
    }
  }
`;

/**
 * model to update user status either active or inactive
 */
export const UPDATE_USER_STATUS: DocumentNode = gql`
  mutation ($adminUserUpdateStatus: AdminUserStatusInput!) {
    adminUserUpdateStatus(adminUserUpdateStatus: $adminUserUpdateStatus) {
      success
      message
    }
  }
`;

/**
 * model to get user profile from list
 */
export const GET_USER_PROFILE: DocumentNode = gql`
  query ($AdminGetUserProfileInput: AdminGetUserProfileInput!) {
    adminGetUserProfile(AdminGetUserProfileInput: $AdminGetUserProfileInput) {
      id
      name
      email
      mobile
      country_code
      dob
      email_verified
      is_admin_verified
      profile_picture
      created_at
      role {
        id
        name
      }
      status {
        id
        name
      }
      userAdditionalInformation {
        id
        total_credits
        overall_rating
      }
      qualificationAccess {
        id
        name
        subjects {
          subject {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * model for transaction list
 */
export const GET_STUDENT_TRANSACTION_HISTORY_LIST: DocumentNode = gql`
  query ($adminListTransactionHistory: AdminListTransactionInput!) {
    adminListStudentTransactionHistory(
      adminListTransactionHistory: $adminListTransactionHistory
    ) {
      listStudentTransactionHistory {
        id
        transaction_id
        credit_spent
        credit_purchased
        source
        created_at
        student {
          id
          name
          email
        }
        request_type {
          id
          name
        }
      }
      count
    }
  }
`;

export const SUBJECT_LIST: DocumentNode = gql`
  query {
    listSubjects {
      id
      name
      icon
      modules {
        id
        name
        icon
        sub_module {
          id
          name
        }
      }
    }
  }
`;

export const LIST_QUALIFICATION: DocumentNode = gql`
  query {
    listQualification {
      id
      name
      subjects {
        subject {
          id
          name
          icon
          publish
          modules {
            id
            name
            icon
            sub_module {
              id
              name
              icon
            }
          }
          appStatus
          adminStatus {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_QUALIFICATION_LEVEL_LIST: DocumentNode = gql`
  query {
    listQualificationLevels {
      id
      name
      levels {
        level {
          id
          name
        }
      }
    }
  }
`;

export const GET_LEVEL_LIST: DocumentNode = gql`
  query {
    adminListLevels {
      id
      name
    }
  }
`;

export const GET_EXAMBOARD_LIST: DocumentNode = gql`
  query {
    listExamboard {
      id
      name
      icon
    }
  }
`;

export const GET_QUALIFICATION_EXAMBOARD_LIST: DocumentNode = gql`
  query {
    listQualificationExamboard {
      id
      name
      examboard {
        id
        name
        appStatus {
          id
        }
        adminStatus {
          id
        }
      }
    }
  }
`;

export const ADMIN_CREATE_QUESTION: DocumentNode = gql`
  mutation ($createQuestionInput: CreateQuestionInput!) {
    adminCreateQuestion(createQuestionInput: $createQuestionInput) {
      success
      message
    }
  }
`;

export const ADMIN_UPDATE_QUESTION: DocumentNode = gql`
  mutation ($updateQuestionInput: UpdateQuestionInput!) {
    adminUpdateQuestions(updateQuestionInput: $updateQuestionInput) {
      success
      message
    }
  }
`;

export const GET_QUESTION_LIST: DocumentNode = gql`
  query ($adminListQuestionsInput: AdminListQuestionsInput!) {
    adminListQuestions(adminListQuestionsInput: $adminListQuestionsInput) {
      listQuestion {
        id
        question_type
        mcq_normal
        max_select_answer
        image_or_text
        title
        answers {
          id
          answer
          isCorrect
        }
        working_step
        video_url
        is_calculator_allowed
        subject {
          id
          name
        }
        module {
          id
          name
        }
        sub_topic {
          id
          name
        }
        exam_board {
          id
          name
        }
        level {
          id
          name
        }
        qualification {
          id
          name
        }
      }
      refetch
      count
    }
  }
`;

export const ADMIN_DELETE_QUESTION: DocumentNode = gql`
  mutation ($id: Int!) {
    adminDeleteQuestion(id: $id) {
      success
      message
    }
  }
`;

export const REFRESH_TOKEN: DocumentNode = gql`
  mutation {
    refreshTokens {
      access_token
      refresh_token
    }
  }
`;

export const GET_SUB_TOPIC_LIST: DocumentNode = gql`
  query {
    adminListSubTopics {
      id
      name
    }
  }
`;

export const ADMIN_VERIFIES_USER: DocumentNode = gql`
  mutation refactored835($adminVerifiesUserInput: AdminVerifiesUserInput!) {
    adminVerifiesUser(adminVerifiesUserInput: $adminVerifiesUserInput) {
      success
      message
    }
  }
`;

export const GET_PREDICTED_PAPER_LIST: DocumentNode = gql`
  query ($listPredictedPaper: AdminListPredictedPaperInput!) {
    adminListPredictedPaper(listPredictedPaper: $listPredictedPaper) {
      predictedPaper {
        id
        title
        paperCount
        year
        status {
          id
          name
        }
        subject {
          id
          name
        }
        qualification {
          id
          name
        }
        examboard {
          id
          name
        }
        tier {
          id
          name
        }
        paperType {
          id
          name
        }
        productType {
          id
          name
        }
        predictedPaperPdf {
          id
          title
          pdf_url
          marks_scheme
        }
      }
      count
    }
  }
`;
// fetch product type list
export const GET_PRODUCT_TYPE_LIST: DocumentNode = gql`
  query {
    listProductType {
      id
      name
      product_id
      price
    }
  }
`;
// fetch tier list
export const GET_TIER_LIST: DocumentNode = gql`
  query {
    listTier {
      id
      name
    }
  }
`;

export const ADMIN_CREATE_PREDICTED_PAPER: DocumentNode = gql`
  mutation ($createPredictedPaper: CreatePredictedPaperInput!) {
    adminCreatePredictedPaper(createPredictedPaper: $createPredictedPaper) {
      success
      message
    }
  }
`;
export const ADMIN_UPDATE_PREDICTED_PAPER: DocumentNode = gql`
  mutation ($updatePredictedPaper: UpdatePredictedPaperInput!) {
    adminUpdatePredictedPaper(updatePredictedPaper: $updatePredictedPaper) {
      success
      message
    }
  }
`;

// fetch product type list
export const GET_PAPER_TYPE_LIST: DocumentNode = gql`
  query {
    listPaperType {
      id
      name
    }
  }
`;

export const LIST_PREDICTED_QUALIFICATION: DocumentNode = gql`
  query {
    listPredictedPaperQualifications {
      id
      name
      examboard {
        id
        name
        appStatus {
          id
          name
        }
        adminStatus {
          id
          name
        }
        subject {
          id
          name
        }
      }
    }
  }
`;
//delete predicted paper
export const ADMIN_DELETE_PREDICTED_PAPER: DocumentNode = gql`
  mutation ($id: Int!) {
    adminDeletePredictedPaper(id: $id) {
      success
      message
    }
  }
`;

export const CREATE_AND_UPDATE_TUTOR_QUALIFICATION_ACCESS: DocumentNode = gql`
  mutation adminCreateAndUpdateTutorQualificationAccess(
    $createTutorQualificationAccessInput: CreateTutorQualificationAccessInput!
  ) {
    adminCreateAndUpdateTutorQualificationAccess(
      updateTutorQualificationAccessInput: $createTutorQualificationAccessInput
    ) {
      success
      message
    }
  }
`;

export const CREATE_DUPLICATE_QUESTION: DocumentNode = gql`
  mutation adminCreateDuplicateQuestion(
    $createDuplicateQuestionInput: CreateDuplicateQuestionInput!
  ) {
    adminCreateDuplicateQuestion(
      createDuplicateQuestionInput: $createDuplicateQuestionInput
    ) {
      success
      message
    }
  }
`;

/**
 * model for transaction list
 */
export const GET_EXAM_PAPER_TRANSACTION_HISTORY_LIST: DocumentNode = gql`
  query ($adminListTransactionInput: AdminListPredictedPaperTransactionInput!) {
    adminListPredictedPaperTransaction(
      adminListTransactionInput: $adminListTransactionInput
    ) {
      transaction {
        id
        transaction_id
        created_at
        price
        user {
          id
          name
          email
        }
        predicted_Paper {
          id
          title
          paperCount
          year
          qualification {
            id
            name
          }
          examboard {
            id
            name
          }
          subject {
            id
            name
          }
          productType {
            id
            name
            price
            product_id
          }
          paperType {
            id
            name
            sort_order
          }
        }
      }
      count
    }
  }
`;

export const ADMIN_CREATE_SESSION: DocumentNode = gql`
  mutation ($adminCreateSessionInput: AdminCreateSessionInput!) {
    adminCreateSession(adminCreateSessionInput: $adminCreateSessionInput) {
      success
      message
    }
  }
`;
export const ADMIN_UPDATE_SESSION: DocumentNode = gql`
  mutation ($adminUpdateSessionInput: AdminUpdateSessionInput!) {
    adminUpdateSession(adminUpdateSessionInput: $adminUpdateSessionInput) {
      success
      message
    }
  }
`;

/**
 * model to get list of users (tuitor/students)
 */
export const ADMIN_LIST_SESSION: DocumentNode = gql`
  query ($adminListSessionsInput: AdminListSessionsInput!) {
    adminListSessions(adminListSessionsInput: $adminListSessionsInput) {
      session {
        id
        name
        date_time
        session_duration
        grade
        session_url
        answer_url
        answer_duration
        average_rating
        created_at
        updated_at
        qualification {
          id
          name
          sort_order
        }
        examBoard {
          id
          name
          icon
          sort_order
        }

        subject {
          id
          name
          icon
          sort_order
        }
        module {
          id
          name
          icon
        }
        subTopic {
          id
          name
          icon
        }
        user {
          id
          name
          email
        }
        status {
          id
          name
        }
      }
      count
    }
  }
`;

export const ADMIN_DELETE_SESSION: DocumentNode = gql`
  mutation ($id: Int!) {
    adminDeleteSession(id: $id) {
      success
      message
    }
  }
`;

export const ADMIN_DUPLICATE_SESSION: DocumentNode = gql`
  mutation adminDuplicateSession(
    $adminDuplicateSessionInput: AdminDuplicateSessionInput!
  ) {
    adminDuplicateSession(
      adminDuplicateSessionInput: $adminDuplicateSessionInput
    ) {
      success
      message
    }
  }
`;

export const GET_STUDENT_SUBSCRIPTIONS_LIST: DocumentNode = gql`
  query ($listSubscriptionDetailsInput: ListSubscriptionDetailsInput!) {
    adminListSubscriptionDetails(
      listSubscriptionDetailsInput: $listSubscriptionDetailsInput
    ) {
      subscription {
        id
        subscr_id
        price
        gateway
        period
        period_type
        status
        inApp_trans_num
        trial
        trial_days
        created_at
        isTrial
        transactions {
          id
          amount
          trans_num
          status
          gateway
          parent_transaction_id
          inApp_trans_num
          expires_at
        }
        user {
          id
          name
          email
        }
      }
      count
    }
  }
`;

export const ADMIN_CREATE_COURSE_MUTATION: DocumentNode = gql`
  mutation adminCreateCourse($create_course_input: CreateCourseInput!) {
    adminCreateCourse(create_course_input: $create_course_input) {
      success
      message
    }
  }
`;

export const ADMIN_UPDATE_COURSE_MUTATION: DocumentNode = gql`
  mutation adminUpdateCourse($update_course_input: UpdateCourseInput!) {
    adminUpdateCourse(update_course_input: $update_course_input) {
      success
      message
    }
  }
`;

export const GET_COURSE_LIST_QUERY: DocumentNode = gql`
  query adminListCourse($list_course_input: AdminListCoursesInput!) {
    adminListCourse(list_course_input: $list_course_input) {
      courses {
        id
        title
        description
        hosted_by
        introduction_video_url
        introduction_video_thumbnail_url
        created_at
        updated_at
        qualification {
          id
          name
        }
        subject {
          id
          name
        }
        module {
          id
          name
        }
        subTopic {
          id
          name
        }
        question_level {
          id
          name
        }
        product_type {
          id
          name
        }
        examboard {
          id
          name
        }
        course_associated_videos {
          id
          title
          description
          url
          thumbnail_url
          duration
          created_at
        }
        status {
          id
          name
        }
        rating
      }
      count
    }
  }
`;

export const ADMIN_GET_COURSE_QUERY: DocumentNode = gql`
  query adminGetCourse($id: Int!) {
    adminGetCourse(id: $id) {
      id
      title
      description
      hosted_by
      introduction_video_url
      introduction_video_thumbnail_url
      created_at
      updated_at
      qualification {
        id
        name
      }
      subject {
        id
        name
      }
      module {
        id
        name
      }
      subTopic {
        id
        name
      }
      question_level {
        id
        name
      }
      product_type {
        id
        name
      }
      examboard {
        id
        name
      }
      course_associated_videos {
        id
        title
        description
        url
        thumbnail_url
        duration
        created_at
      }
      status {
        id
        name
      }
    }
  }
`;

export const ADMIN_DELETE_COURSE_MUTATION: DocumentNode = gql`
  mutation adminDeleteCourse($id: Int!) {
    adminDeleteCourse(id: $id) {
      success
      message
    }
  }
`;

export const ADMIN_LIST_PRODUCT_TYPE: DocumentNode = gql`
  query adminListCourseProductType {
    adminListCourseProductType {
      course_product_type {
        id
        name
        product_id
        price
        created_at
      }
      count
    }
  }
`;

export const ADMIN_DUPLICATE_COURSE_MUTATION: DocumentNode = gql`
  mutation adminDuplicateCourse($id: Int!) {
    adminDuplicateCourse(id: $id) {
      success
      message
    }
  }
`;

export const ADMIN_LIST_COURSE_TRANSACTION_QUERY: DocumentNode = gql`
  query adminListCoursesTransactions(
    $list_courses_transactions: AdminListCoursesTransactionInput!
  ) {
    adminListCoursesTransactions(
      list_courses_transactions: $list_courses_transactions
    ) {
      transactions {
        id
        transaction_id
        token
        platform
        price
        created_at
        user {
          id
          name
          email
        }
        course {
          id
          title
          qualification {
            id
            name
          }
          product_type {
            id
            name
          }
        }
      }
      count
    }
  }
`;
