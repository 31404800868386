import { Component, OnInit } from "@angular/core";
import { ModalService } from "src/app/core/service/modal.service";
import { DeleteTuitorComponent } from "src/app/modules/main/tuitor/delete-tuitor/delete-tuitor.component";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  constructor(private modal: ModalService) {}

  ngOnInit(): void {}

  openF() {
    this.modal.openModal(DeleteTuitorComponent);
  }
}
