<div class="modal-header">
    <h4 class="modal-title">Delete</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()">
    </button>
</div>
<div class="modal-body">
    <h5>Are you sure?</h5>
    <p>You would not be able to recover this record.</p>
</div>
<div class="modal-footer">
    <button class="btn btn-primary" (click)="closeModal()">Delete</button>
</div>