import { NgModule } from "@angular/core";
import { RouterModule, Route } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";
import { MainGuard } from "./core/guards/main.guard";
const routes: Route[] = [
  {
    path: "",
    loadChildren: () =>
      import("./modules/auth/auth.module").then((m) => m.AuthModule),
    canActivate: [MainGuard],
  },
  {
    path: "main",
    loadChildren: () =>
      import("./modules/main/main.module").then((m) => m.MainModule),
    canActivate: [AuthGuard],
  },
  { path: "signed-in-redirect", pathMatch: "full", redirectTo: "example" },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled"
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
