import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "src/app/modules/auth/login/login.component";
import { Route, RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ForgotPasswordComponent } from "src/app/modules/auth/forgot-password/forgot-password.component";
import { PATH } from "src/app/common/constant";

const routes: Route[] = [
  { path: "", component: LoginComponent },
  { path: PATH.AUTH.FORGOT, component: ForgotPasswordComponent },
];
@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [LoginComponent],
})
export class AuthModule {}
