import { Component, HostBinding } from "@angular/core";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { ThemeOptions } from "src/app/modules/main/ThemeOptions/store/theme-options";
import { Router } from "@angular/router";
import { PARENT_PATH } from "src/app/common/constant";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
})
export class HeaderComponent {
  faEllipsisV = faEllipsisV;

  constructor(public globals: ThemeOptions, private router: Router) {}

  @HostBinding("class.isActive")
  get isActiveAsGetter() {
    return this.isActive;
  }

  isActive: boolean;

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  toggleHeaderMobile() {
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
  }

  logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    this.router.navigateByUrl(`${PARENT_PATH.AUTH}`);
  }
}
