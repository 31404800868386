import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "src/app/core/service/auth.service";
import { PARENT_PATH } from "src/app/common/constant";
@Injectable({
  providedIn: "root",
})
export class MainGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(): boolean {
    if (this.authService.isLoggedIn()) {
      this.router.navigateByUrl(`${PARENT_PATH.MAIN}`);
      return false;
    } else {
      return true;
    }
  }
}
