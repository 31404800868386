import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "src/app/core/service/auth.service";
import { PATH } from "src/app/common/constant";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(): boolean {
    if (!this.authService.isLoggedIn()) {
      this.router.navigateByUrl(`${PATH.AUTH.LOGIN}`);
      return false;
    } else {
      return true;
    }
  }
}
