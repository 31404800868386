import { NgModule } from "@angular/core";
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  Observable,
} from "@apollo/client/core";
import { HttpLink } from "apollo-angular/http";
import { setContext } from "@apollo/client/link/context";
import { environment } from "src/environments/environment";
import { onError } from "apollo-link-error";
import { REFRESH_TOKEN } from "src/app/graphql/model";
import { AuthService } from "src/app/core/service/auth.service";
const uri = environment.baseUrl;

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
export let apolloClient: ApolloClient<NormalizedCacheObject>;
export function createApollo(httpLink: HttpLink, service: AuthService) {
  const auth = setContext((operation, context) => {
    let token = localStorage.getItem("access_token");
    if (token === null) {
      return {
        headers: {
          app_access_token: environment.appAccessToken,
        },
      };
    } else {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
          app_access_token: environment.appAccessToken,
        },
      };
    }
  });

  //  });
  // });
  const errorLink: any = onError(
    ({ graphQLErrors, networkError, operation, forward }): any => {
      // const errorLink: any = onError(({ graphQLErrors, operation, forward }) => {
      // User access token has expired
      if (graphQLErrors && graphQLErrors[0].message === "Unauthorized") {
        // We assume we have both tokens needed to run the async request
        // if (refreshToken && clientToken) {
        // Let's refresh token through async request
        return new Observable((observer) => {
          const refresh_token = localStorage.getItem("refresh_token");
          const auth1 = new ApolloLink((operation, forward) => {
            operation.setContext({
              headers: {
                Authorization: `Bearer ${refresh_token}`,
                app_access_token: environment.appAccessToken,
              },
            });
            return forward(operation);
          });
          let apoloClient = new ApolloClient({
            link: ApolloLink.from([auth1, httpLink.create({ uri })]),
            cache: new InMemoryCache(),
          });
          apoloClient
            .mutate({
              mutation: REFRESH_TOKEN,
            })
            //         .then(async (res) => {
            .then((res) => {
              let accessToken = res.data?.refreshTokens?.access_token;
              let refreshToken = res.data?.refreshTokens?.refresh_token;
              operation.setContext({
                headers: {
                  ...operation.getContext().headers,
                  Authorization: `Bearer ${accessToken}`,
                },
              });

              localStorage.removeItem("access_token");
              localStorage.removeItem("refresh_token");
              localStorage.setItem("access_token", accessToken);
              localStorage.setItem("refresh_token", refreshToken);

              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };

              // Retry last failed request
              forward(operation).subscribe(subscriber);
            })
            .catch((error) => {
              // No refresh or client token available, we force user to login
              observer.error(error);
              localStorage.removeItem("access_token");
              localStorage.removeItem("refresh_token");
              location.reload();
            });
        });
      }
    }
  );

  // return await forward(operation);

  //  });
  // });
  // return new ApolloClient({
  //   link: ApolloLink.from([errorLink, auth, httpLink.create({ uri })]), // errorlink.concat(auth.concat())
  //   cache: new InMemoryCache(),
  //   defaultOptions: defaultOptions,
  // });

  return {
    link: ApolloLink.from([auth, errorLink, httpLink.create({ uri })]),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
  };
  // })
  //   })
}
@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
