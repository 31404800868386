import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Injectable({
  providedIn: "root",
})


export class ModalService {
  private modal: any;

  constructor(private ngModal: NgbModal) {}

  /**
   * function to open popup with bootstrap modal.
   * @param component
   * @param data
   * @param size
   * @returns
   */
  openModal(component: any, data?: any, size?: any) {
    this.modal = this.ngModal.open(component, {
      size: size ? size : "md",
      centered: true,
      keyboard: false,
      backdrop: "static",
    });
    if (data) {
      Object.keys(data).forEach((key) => {
        this.modal.componentInstance[key] = data[key];
      });
    }
    return this.modal;
  }

  /**
   * function to close any active modal.
   * @param value
   */
  closeModal(value?: any) {
    this.modal.close();
  }
}


