import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable, Subject, firstValueFrom } from "rxjs";
import {
  SubscriptionInputObj,
  adminListUser,
  getTransactionHistory,
  getUser,
  loginModel,
  userStatus,
} from "src/app/core/modals/modal";
import {
  ADMIN_LIST_USER,
  GET_STUDENT_TRANSACTION_HISTORY_LIST,
  GET_USER_PROFILE,
  LOGIN,
  UPDATE_USER_STATUS,
  SUBJECT_LIST,
  GET_LEVEL_LIST,
  GET_EXAMBOARD_LIST,
  ADMIN_CREATE_QUESTION,
  GET_QUESTION_LIST,
  ADMIN_UPDATE_QUESTION,
  REFRESH_TOKEN,
  GET_SUB_TOPIC_LIST,
  ADMIN_VERIFIES_USER,
  ADMIN_DELETE_QUESTION,
  LIST_QUALIFICATION,
  GET_QUALIFICATION_LEVEL_LIST,
  GET_QUALIFICATION_EXAMBOARD_LIST,
  GET_PRODUCT_TYPE_LIST,
  LIST_PREDICTED_QUALIFICATION,
  GET_PREDICTED_PAPER_LIST,
  ADMIN_CREATE_PREDICTED_PAPER,
  ADMIN_DELETE_PREDICTED_PAPER,
  ADMIN_UPDATE_PREDICTED_PAPER,
  GET_PAPER_TYPE_LIST,
  GET_TIER_LIST,
  CREATE_DUPLICATE_QUESTION,
  CREATE_AND_UPDATE_TUTOR_QUALIFICATION_ACCESS,
  GET_EXAM_PAPER_TRANSACTION_HISTORY_LIST,
  ADMIN_CREATE_SESSION,
  ADMIN_UPDATE_SESSION,
  ADMIN_LIST_SESSION,
  ADMIN_DELETE_SESSION,
  ADMIN_DUPLICATE_SESSION,
  GET_STUDENT_SUBSCRIPTIONS_LIST,
  ADMIN_CREATE_COURSE_MUTATION,
  ADMIN_UPDATE_COURSE_MUTATION,
  GET_COURSE_LIST_QUERY,
  ADMIN_GET_COURSE_QUERY,
  ADMIN_DELETE_COURSE_MUTATION,
  ADMIN_LIST_PRODUCT_TYPE,
  ADMIN_DUPLICATE_COURSE_MUTATION,
  ADMIN_LIST_COURSE_TRANSACTION_QUERY,
} from "src/app/graphql/model";
import { ExamboardResponseEntity } from "src/app/modules/main/question/qualification-examboard.entity";
import { AdminListPredictedPaper, ListExamboard, ListExamboardEntity, ListPaperType, ListPaperTypeEntity, ListPredictedPaperQualification, ListProductType, ListProductTypeEntity, ListSubject, ListSubjectEntity, ListTier, ListTierEntity, ListpredictedPaperEntity, ListpredictedPaperQualificationEntity } from "src/app/modules/main/predicted-paper/enities/list-predicted-response";
import { PredictedPaperInputEntity } from "src/app/modules/main/predicted-paper/enities/list-predicted-input";
import { CreatePredictedPaper } from "src/app/modules/main/predicted-paper/enities/create-predicted-input";
import { AdminCreatePredictedPaper, CreatePredictedPaerEntity, UpdatePredictedPaerEntity, AdminDeletePredictedPaper, DeletePredictedPaperEntity } from "src/app/modules/main/predicted-paper/enities/create-predicted-response";
import { AdminListPredictedPaperTransaction, ListExamPaperTransactionEntity } from "src/app/modules/main/exam-paper-transaction/entities/list-exam-paper-response";
import { AdminDeleteSession, AdminDuplicateSession, AdminDuplicateSessionClass, CreateSession, DeleteSessionEntity } from "src/app/modules/main/session/entities/create-session.input";
import { AdminCreateSession, AdminCreateSessionEntity, AdminUpdateSessionEntity, SessionFilters } from "src/app/modules/main/session/entities/list-session.input";
import { AdminListSessionEntity, AdminListSessions } from "src/app/modules/main/session/entities/list-session.response";
import { AdminListUsers, DataAdminListUser } from "../modules/main/tuitor/entities/list-tutor-response.entity";
import { AdminGetDeleteInputEntity, CreateCourseInputEntity, UpdateCourseInputEntity, courseTransactionInputEntity, getCourseListInputEntity } from "../modules/main/courses/entities/course-form-input.entity";
import { AdminCourseResponseEntity, AdminCreateResponseObject, AdminCreateUpdateResponse, AdminDeleteResponseObject, AdminDuplicateCourseResponseObject, AdminGetCourseResponseEntity, AdminListCourseProductType, AdminListCourseResponse, AdminListCourseResponseEntity, AdminListCoursesTransactions, AdminListCoursesTransactionsResponse, AdminProductTypeResponseObject, AdminUpdateResponseObject, Course } from "../modules/main/courses/entities/course-list-response.entity";

@Injectable({
  providedIn: "root",
})
export class Authenticate extends Subject<Object> {
  constructor(private apollo: Apollo) {
    super();
  }
  /**
   * login function
   * @param credentials
   * @returns
   */
  signIn(credentials: loginModel) {
    return this.apollo.mutate({
      mutation: LOGIN,
      variables: {
        loginInput: credentials,
      },
    });
  }

  /**
   * function to get list of users
   * @param credentials
   * @returns
   */
  adminListUsers(credentials: adminListUser) {
    return this.apollo.query({
      query: ADMIN_LIST_USER,
      variables: {
        listUserInput: credentials,
      },
    });
  }

  /**
   * function to get profile of logged in user
   * @returns
   */
  getUserProfile() {
    return this.apollo.query({
      query: GET_USER_PROFILE,
    });
  }

  /**
   * function to update user status
   */
  updateUserStatus(credentials: userStatus) {
    return this.apollo.mutate({
      mutation: UPDATE_USER_STATUS,
      variables: {
        adminUserUpdateStatus: credentials,
      },
    });
  }

  /**
   * function to get user from list
   * @param credencials
   * @returns
   */
  getUserById(credencials: getUser) {
    return this.apollo.query({
      query: GET_USER_PROFILE,
      variables: {
        AdminGetUserProfileInput: credencials,
      },
    });
  }

  /**
   * function to get list of student transaction hostory
   */
  getStudentTransactionHistory(credencial: getTransactionHistory) {
    return this.apollo.query({
      query: GET_STUDENT_TRANSACTION_HISTORY_LIST,
      variables: {
        adminListTransactionHistory: credencial,
      },
    });
  }
  /**
   * function to get list of student transaction hostory
   */
  getStudentSubscriptions(credencial: SubscriptionInputObj) {
    return this.apollo.query({
      query: GET_STUDENT_SUBSCRIPTIONS_LIST,
      variables: {
        listSubscriptionDetailsInput: credencial,
      },
    });
  }

  /**
   * function to get list of subjects
   * @returns
   */
  getSubjects() {
    return this.apollo.query({
      query: SUBJECT_LIST,
    });
  }
  /**
   * function to get list of subjects
   * @returns
   */
  async getSubjectPredicted(): Promise<ListSubject[]> {
    const response: ListSubjectEntity = await firstValueFrom(
      this.apollo.query({
        query: SUBJECT_LIST,
      })
    );
    return response?.data?.listSubjects;
  }
  /**
    * function to get list of listQualification
    * @returns
    */

  async listQualificationPredicted(): Promise<ListPredictedPaperQualification[]> {
    const response: ListpredictedPaperQualificationEntity = await firstValueFrom(
      this.apollo.query({
        query: LIST_PREDICTED_QUALIFICATION,
      })
    );
    return response?.data?.listPredictedPaperQualifications;
  }
  /**
     * function to get list of examboards
     */
  async getPredictedExamBoardList(): Promise<ListExamboard[]> {
    const response: ListExamboardEntity = await firstValueFrom(
      this.apollo.query({
        query: GET_EXAMBOARD_LIST,
      })
    );
    return response?.data?.listExamboard;
  }

  /**
   * function to get list of listQualification
   * @returns
   */

  listQualification(appStatus: JSON) {
    return this.apollo.query({
      query: LIST_QUALIFICATION,
      variables: {
        appStatus: appStatus,
      },
    });
  }

  getQualificationLevelsList() {
    return this.apollo.query({
      query: GET_QUALIFICATION_LEVEL_LIST,
    });
  }

  /**
   * function to get list of levels for creating questions
   * @returns
   */
  getLevelsList() {
    return this.apollo.query({
      query: GET_LEVEL_LIST,
    });
  }

  /**
   * function to get list of subtopics for create/edit questions
   */
  getSubTopicList() {
    return this.apollo.query({
      query: GET_SUB_TOPIC_LIST,
    });
  }

  /**
   * function to get list of examboards
   */
  // getExamBoardList() {
  //   return this.apollo.query({
  //     query: GET_EXAMBOARD_LIST,
  //   });
  // }

   /**
   * function to get list of qualification examboards
   */
   getQualificationExamBoardList():Observable<ExamboardResponseEntity> {
    return this.apollo.query({
      query: GET_QUALIFICATION_EXAMBOARD_LIST,
    });
  }
  /**
     * function to get list of productType
     */
  async getProductList(): Promise<ListProductType[]> {
    const response: ListProductTypeEntity = await firstValueFrom(
      this.apollo.query({
        query: GET_PRODUCT_TYPE_LIST,
      })
    );

    return response?.data?.listProductType;
  }
  /**
   * function to create questions
   */

  createQuestions(credentials: any) {
    return this.apollo.mutate({
      mutation: ADMIN_CREATE_QUESTION,
      variables: {
        createQuestionInput: credentials,
      },
      // refetchQueries: [{ query: GET_QUESTION_LIST }],
    });
  }

  /**function to update questions */
  updateQuestions(credentials: any) {
    return this.apollo.mutate({
      mutation: ADMIN_UPDATE_QUESTION,
      variables: {
        updateQuestionInput: credentials,
      },
    });
  }

  /**
   * function to get list of questions
   */

  getQuestionList(credentials: getTransactionHistory) {
    return this.apollo.query({
      query: GET_QUESTION_LIST,
      variables: {
        adminListQuestionsInput: credentials,
      },
    });
  }

  /**
   * function to delete a question
   */
  deleteQuestion(questionId: any) {
    return this.apollo.mutate({
      mutation: ADMIN_DELETE_QUESTION,
      variables: {
        id: questionId,
      },
    });
  }

  /**function to get refresh token */
  refreshToken() {
    return this.apollo.mutate({
      mutation: REFRESH_TOKEN,
    });
  }

  /**
   * function to update user status by admin
   */
  adminVerifiedUserStatus(credentials: userStatus) {
    return this.apollo.mutate({
      mutation: ADMIN_VERIFIES_USER,
      variables: {
        adminVerifiesUserInput: credentials,
      },
    });
  }

  /**
   * function to get list of questions
   */

  async getPredictedPaperList(variables: PredictedPaperInputEntity): Promise<AdminListPredictedPaper> {
    const response: ListpredictedPaperEntity = await firstValueFrom(
      this.apollo.query({
        query: GET_PREDICTED_PAPER_LIST,
        variables
      })
    );
    return response?.data?.adminListPredictedPaper;

  }

  /**
   * function to get list of Tier
   */
  async getTierList(): Promise<ListTier[]> {
    const response: ListTierEntity = await firstValueFrom(
      this.apollo.query({
        query: GET_TIER_LIST,
      })
    );
    return response?.data?.listTier;
  }


  async createPredictedPaper(variables: CreatePredictedPaper): Promise<AdminCreatePredictedPaper> {
    const response: CreatePredictedPaerEntity = await firstValueFrom(

      this.apollo.mutate({
        mutation: ADMIN_CREATE_PREDICTED_PAPER,
        variables: {
          createPredictedPaper: variables,
        },
      })
    );
    return response?.data?.adminCreatePredictedPaper;
  }

  async updatePredictedPaper(variables: CreatePredictedPaper): Promise<AdminCreatePredictedPaper> {
    const response: UpdatePredictedPaerEntity = await firstValueFrom(

      this.apollo.mutate({
        mutation: ADMIN_UPDATE_PREDICTED_PAPER,
        variables: {
          updatePredictedPaper: variables,
        },
      })
    );
    return response?.data?.adminUpdatePredictedPaper;

  }


  /**
     * function to get list of productType
     */
  async getPaperTypeList(): Promise<ListPaperType[]> {
    const response: ListPaperTypeEntity = await firstValueFrom(

      this.apollo.query({
        query: GET_PAPER_TYPE_LIST,
      })
    );

    return response?.data?.listPaperType;
  }

  //Delete predicted paper 
  async deletePredictedPaper(id: number): Promise<AdminDeletePredictedPaper> {
   
    const response: DeletePredictedPaperEntity = await firstValueFrom(

      this.apollo.mutate({
        mutation: ADMIN_DELETE_PREDICTED_PAPER,
                  variables:{
                    id
                  }
              })
    );
    return response?.data?.adminDeletePredictedPaper;
  }
  createAndUpdateTutorQualification(data) {
    return this.apollo.mutate({
      mutation: CREATE_AND_UPDATE_TUTOR_QUALIFICATION_ACCESS,
      variables: {
        createTutorQualificationAccessInput: data,
      },
    });
  }

  addDuplicateQuestion(quesId) {
    return this.apollo.query({
      query: CREATE_DUPLICATE_QUESTION,
      variables: {
        createDuplicateQuestionInput: {
          question_id: quesId,
        },
      },
    });
  }
  //fetch student exam Paper Trasaction List
  async getExamPaperTransactionList(data): Promise<AdminListPredictedPaperTransaction> {
   
    const response: ListExamPaperTransactionEntity = await firstValueFrom(
      this.apollo.query({
        query: GET_EXAM_PAPER_TRANSACTION_HISTORY_LIST,
        variables:{
          adminListTransactionInput:data
        }
      })
    );
    return response?.data?.adminListPredictedPaperTransaction
    ;
  }

  /**
   * function to get list of questions
   */

  async getSessionTutorList(credentials: adminListUser): Promise<DataAdminListUser> {
    const response: AdminListUsers = await firstValueFrom(
      this.apollo.query({
        query: ADMIN_LIST_USER,
        variables:{
          listUserInput: credentials
        }
      })
    );
    return response?.data?.adminListUser;

  }
  async createSession(variables: CreateSession): Promise<AdminCreateSession> {
    const response: AdminCreateSessionEntity = await firstValueFrom(

      this.apollo.mutate({
        mutation: ADMIN_CREATE_SESSION,
        variables: {
          adminCreateSessionInput: variables,
        },
      })
    );
    return response?.data?.adminCreateSession;
  }
  async updateSession(variables: CreateSession): Promise<AdminCreateSession> {
    const response: AdminUpdateSessionEntity = await firstValueFrom(

      this.apollo.mutate({
        mutation: ADMIN_UPDATE_SESSION,
        variables: {
          adminUpdateSessionInput: variables,
        },
      })
    );
    return response?.data?.adminUpdateSession;
  }
  /**
   * function to get list of sessions
   */

  async getSessionList(variables: SessionFilters): Promise<AdminListSessions> {
    const response: AdminListSessionEntity = await firstValueFrom(
      this.apollo.query({
        query: ADMIN_LIST_SESSION,
        variables: {
          adminListSessionsInput: variables,
        },        
      })
    );
    return response?.data?.adminListSessions;

  }

  /**
   * delete session from the list
   */
  async deleteSession(variables: number): Promise<AdminDeleteSession> {
    const response: DeleteSessionEntity = await firstValueFrom(

      this.apollo.mutate({
        mutation: ADMIN_DELETE_SESSION,
        variables:{id:variables},
      })
    );
    return response?.data?.adminDeleteSession;
  }
  async addDuplicateSession(id: number): Promise<AdminDuplicateSessionClass> {
    const response: AdminDuplicateSession = await firstValueFrom(

      this.apollo.mutate({
        mutation: ADMIN_DUPLICATE_SESSION,
        variables:{adminDuplicateSessionInput :{session_id:id}},
      })
    );
    
    return response?.data?.adminDuplicateSession;
  }

  async adminCreateCourse(variables: CreateCourseInputEntity): Promise<AdminCreateUpdateResponse> {
    const response: AdminCourseResponseEntity = await firstValueFrom(
      this.apollo.mutate({
        mutation: ADMIN_CREATE_COURSE_MUTATION,
        variables
      })
    );
    return (response?.data as AdminCreateResponseObject)?.adminCreateCourse;;
  }

  async adminUpdateCourse(variables: UpdateCourseInputEntity): Promise<AdminCreateUpdateResponse> {
    const response: AdminCourseResponseEntity = await firstValueFrom(
      this.apollo.mutate({
        mutation: ADMIN_UPDATE_COURSE_MUTATION,
        variables
      })
    );
    return (response?.data as AdminUpdateResponseObject)?.adminUpdateCourse;
  }

  async adminListCourse(variables: getCourseListInputEntity): Promise<AdminListCourseResponse> {
    const response: AdminListCourseResponseEntity = await firstValueFrom(
      this.apollo.query({
        query: GET_COURSE_LIST_QUERY, 
        variables
      })
    );
    return response?.data?.adminListCourse;
  }

  async adminGetCourse(variables: AdminGetDeleteInputEntity): Promise<Course> {
    const response: AdminGetCourseResponseEntity = await firstValueFrom(
      this.apollo.query({
        query: ADMIN_GET_COURSE_QUERY, 
        variables
      })
    );
    return response?.data?.adminGetCourse;
  }

  async adminDeleteCourse(variables: AdminGetDeleteInputEntity): Promise<AdminCreateUpdateResponse> {
    const response: AdminCourseResponseEntity = await firstValueFrom(
      this.apollo.mutate({
        mutation: ADMIN_DELETE_COURSE_MUTATION, 
        variables
      })
    );
    return (response?.data as AdminDeleteResponseObject)?.adminDeleteCourse;
  }

  async adminDuplicateCourse(variables: AdminGetDeleteInputEntity): Promise<AdminCreateUpdateResponse> {
    const response: AdminCourseResponseEntity = await firstValueFrom(
      this.apollo.mutate({
        mutation: ADMIN_DUPLICATE_COURSE_MUTATION,
        variables
      })
    );
    return (response?.data as AdminDuplicateCourseResponseObject)?.adminDuplicateCourse;
  }

  async adminListProductType(): Promise<AdminListCourseProductType> {
    const response: AdminCourseResponseEntity = await firstValueFrom(
      this.apollo.query({
        query: ADMIN_LIST_PRODUCT_TYPE
      })
    );
    return (response.data as AdminProductTypeResponseObject)?.adminListCourseProductType;
  }

  async adminListCourseTransaction(variables: courseTransactionInputEntity): Promise<AdminListCoursesTransactions> {
    const response: AdminCourseResponseEntity = await firstValueFrom(
      this.apollo.query({
        query: ADMIN_LIST_COURSE_TRANSACTION_QUERY,
        variables
      })
    );
    return (response.data as AdminListCoursesTransactionsResponse)?.adminListCoursesTransactions;
  }
  
}
