<div class="d-flex">
    <div class="header-btn-lg pe-0">
        <div class="widget-content p-0">
            <div class="widget-content-wrapper">
                <div class="widget-content-left" style="margin-top: 7px;">
                    <button type="button" class="mb-2 me-2 btn" style="color: #02305c;background:white;" (click)="logout()">Logout</button>
                    <!-- <div class="btn-group" ngbDropdown placement="bottom-right">
                        <button type="button" class="btn btn-link p-0 me-2" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <img width="42" src="./assets/images/avatars/user.png" alt="" class="rounded-circle">
                </div>
              </span>
            </button>
                        <div ngbDropdownMenu>
                            <button class="dropdown-item" (click)="logout()">Logout</button>

                        </div>
                    </div> -->
                </div>

            </div>
        </div>
    </div>
</div>