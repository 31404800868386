import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "src/app/app-routing.module";
import { CommonModule, DatePipe } from "@angular/common";
import {  HttpClientModule } from "@angular/common/http";
import { AppComponent } from "src/app/app.component";
import { BaseChartDirective } from 'ng2-charts';
import { AuthModule } from "src/app/modules/auth/auth.module";
import { MainModule } from "src/app/modules/main/main.module";

import { GraphQLModule } from "src/app/graphql.module";
// DEMO PAGES
import { ToastrModule } from "ngx-toastr";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgxSpinnerModule } from "ngx-spinner";
import { AuthService } from "src/app/core/service/auth.service";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { OWL_DATE_TIME_LOCALE, OwlDateTimeModule, OwlNativeDateTimeModule } from "@danielmoncada/angular-datetime-picker";

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MainModule,
    GraphQLModule,
    AuthModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    BaseChartDirective,
    LoadingBarModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [
   DatePipe,
    AuthService,
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' }
    // { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
