import { Component } from "@angular/core";

import {
  animate,
  query,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { ThemeOptions } from "src/app/modules/main/ThemeOptions/store/theme-options";

@Component({
  selector: "app-base-layout",
  templateUrl: "./base-layout.component.html",
  animations: [
    trigger("architectUIAnimation", [
      transition("* <=> *", [
        query(
          ":enter, :leave",
          [
            style({
              opacity: 0,
              display: "flex",
              flex: "1",
              transform: "translateY(-20px)",
              flexDirection: "column",
            }),
          ],
          { optional: true }
        ),
        query(
          ":enter",
          [
            animate(
              "200ms ease",
              style({ opacity: 1, transform: "translateY(0)" })
            ),
          ],
          { optional: true }
        ),

        query(
          ":leave",
          [
            animate(
              "200ms ease",
              style({ opacity: 0, transform: "translateY(-20px)" })
            ),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class BaseLayoutComponent {
  constructor(
    public globals: ThemeOptions
  ) {}

  ngOnInit() {}

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }
}
