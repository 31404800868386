<div class="h-100 bg-plum-plate bg-animation">
    <div class="d-flex h-100 justify-content-center align-items-center">
        <div class="mx-auto app-login-box col-md-4">
            <div class="app-logo-inverse mx-auto mb-3"></div>
            <form [formGroup]="loginForm">
                <div class="modal-dialog w-100 mx-auto">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="h5 modal-title text-center">
                                <h4 class="mt-2">
                                    <span>Welcome to Sign In</span>
                                    <!-- <div>Please sign in to your account below.</div> -->
                                </h4>
                            </div>
                            <div id="exampleInputGroup1" role="group" aria-describedby="exampleInputGroup1__BV_description_" class="mb-3">
                                <div>
                                    <input id="username" type="email" placeholder="Enter email..." required="required" aria-required="true" class="form-control" aria-describedby="exampleInputGroup1__BV_description_" formControlName="username">
                                    <span class="error-message" *ngIf="(submitted || controls.username?.touched) && controls.username?.errors?.pattern">Enter a valid email</span>
                                    <span class="error-message" *ngIf="(submitted || controls.username?.touched) && controls.username?.errors?.required">Email is required</span>
                                </div>
                            </div>
                            <div id="exampleInputGroup2" role="group" class="mb-3">
                                <div><input id="password" type="password" placeholder="Enter password..." required="required" class="form-control" formControlName="password" (keydown.enter)="submit()"></div>
                                <span class="error-message" *ngIf="(submitted || controls.password?.touched) && controls.password?.errors?.pattern">Enter a valid password</span>
                                <span class="error-message" *ngIf="(submitted || controls.password?.touched) && controls.password?.errors?.required">Password is required</span>
                            </div>
                            <!-- <div class="custom-control custom-checkbox form-check">
                                <input id="exampleCheck" type="checkbox" name="check" autocomplete="off" class="form-check-input" value="true">
                                <label for="exampleCheck" class="form-label form-check-label">
                                Keep me logged in
                            </label>
                            </div>
                            <div class="divider"></div>
                            <h6 class="mb-0">
                                No account?
                                <a [routerLink]="" class="text-primary">Sign up now</a>
                            </h6> -->
                        </div>
                        <div class="modal-footer clearfix">
                            <!-- <div class="float-start"><a class="btn-lg btn btn-link" (click)="moveToForgot()">Recover Password</a></div> -->
                            <div class="float-end">
                                <button type="button" class="btn btn-primary btn-lg" (click)="submit()">Sign In</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<router-outlet></router-outlet>