import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { PARENT_PATH, PATH, REGEX } from "src/app/common/constant";
import { ToasterService } from "src/app/core/service/toastr.service";
import { AuthService } from "src/app/core/service/auth.service";
import { SpinnerService } from "src/app/core/service/spinner.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.sass"],
})
export class LoginComponent implements OnInit {
  constructor(
    private form: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private toastr: ToasterService,
    private spinner: SpinnerService
  ) {} //
  submitted = false;
  loginForm: FormGroup;
  ngOnInit(): void {
    this.initLoginForm();
  }

  initLoginForm() {
    this.loginForm = this.form.group({
      username: ["", [Validators.required, Validators.pattern(REGEX.EMAIL)]],
      password: ["", [Validators.required, Validators.pattern(REGEX.PASSWORD)]],
    });
  }

  get controls() {
    return this.loginForm.controls;
  }

  submit() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.spinner.showSpinner();
      let data = {
        ...this.loginForm.value,
        role: 3,
      };
      this.authService.signIn(data).subscribe(
        (res: any) => {
          this.toastr.successToastr("Successfully signed in");
          localStorage.setItem("access_token", res.data.login.access_token);
          localStorage.setItem("refresh_token", res.data.login.refresh_token);
          this.spinner.hideSpinner();
          this.router.navigateByUrl(`${PARENT_PATH.MAIN}`);
        },
        (err: any) => {
          this.toastr.errToastr(err);
          this.spinner.hideSpinner();
        }
      );
    }
  }

  moveToForgot() {
    this.router.navigateByUrl(`${PATH.AUTH.FORGOT}`);
  }
}
